const formUtil = require('../../../../src/js/form-util');

window.addEventListener('submit', (form) => {
    let $form = $(form.target);
    let container = form.target.parentNode.parentNode;

    if (formUtil.validateForm($form)) {
        const pixel = document.createElement("img");
        pixel.height = 1;
        pixel.width = 1;
        pixel.style.borderStyle = "none";
        pixel.alt = "";

        if ($form.find('[name="FORMTYPE"]').val() === 'Service Enquiry') {
            pixel.src = "https://insight.adsrvr.org/track/pxl/?adv=n4lgnuw&ct=0:8r8keon&fmt=3";
            container.appendChild(pixel);
        } else if ($form.find('[name="ENQUIRYTYPE"]')) {
            switch ($form.find('[name="ENQUIRYTYPE"]').val()) {
                case 'General Enquiry':
                    pixel.src = "https://insight.adsrvr.org/track/pxl/?adv=n4lgnuw&ct=0:vb1mgxn&fmt=3";
                    break;
                case 'Test Drive':
                    pixel.src = "https://insight.adsrvr.org/track/pxl/?adv=n4lgnuw&ct=0:yqzrzh0&fmt=3";
                    break;
                default:
            }
            container.appendChild(pixel);
        }
    }
});