const utils = require('../../../../src/js/utils');

const $phoneSelect = $('[phone-select]');
const $phoneSelectOptions = $('.call-options');
const $phoneSelectButton = $('[phone-select-button]');
const $phoneSelectCall = $('[phone-select-call]');
const $phoneSelectBackdrop = $('.call-options__backdrop');

$phoneSelectButton.click(function () {
    $phoneSelectBackdrop.toggleClass('active');
    $phoneSelectOptions.toggleClass('active');

    if ($phoneSelectBackdrop.hasClass('active')) {
        // utils.lockBody(true);
        utils.fireEvent('stopScroll');
    } else {
        // utils.lockBody(false);
        utils.fireEvent('startScroll');
    }
});

$phoneSelectBackdrop.click(() => {
    $phoneSelectBackdrop.removeClass('active');
    $phoneSelectOptions.removeClass('active');

    // utils.lockBody(false);
    utils.fireEvent('startScroll');
});

$phoneSelect.on('change', (e) => {
    const tel = e.target.value.split('|');
    $phoneSelectCall.text(`Call ${tel[0]}`);
    $phoneSelectCall.attr('href', `tel:${tel[1]}`);
});